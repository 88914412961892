import { BrowserRouter, RouteObject, useRoutes } from "react-router-dom";
import { Home } from "./pages/Home";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/:code",
    element: <Home />,
  },
];

const Routes = () => useRoutes(routes);

export const Router = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);
