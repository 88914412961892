import copy from "copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../App";
import { useParams, useSearchParams } from "react-router-dom";

const defaultContent = `# Untitled Unity Code

![](https://source.unsplash.com/random/600x200?sig=0)

> Hello, world.`;

export const Home = () => {
  const params = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  const paramsData = queryParams.get("data");

  const [code, setCode] = useState(params["code"] || undefined);
  const [content, setContent] = useState(
    paramsData ? paramsData : defaultContent
  );

  const checked = useRef(false);

  useEffect(() => {
    if (params["code"] && !checked.current) {
      if (paramsData) {
        toast.warning(`Unity Code overridden by query data.`, toastOptions);
        return;
      }

      const resolveAfter3Sec = new Promise((resolve, reject) =>
        setTimeout(reject, 1000)
      );

      toast.promise(
        resolveAfter3Sec,
        {
          pending: `"${params["code"]}"`,
          success: `Code found!`,
          error: `Code not found, consider recharging.`,
        },
        toastOptions
      );

      checked.current = true;
    }
  }, [params, paramsData]);

  const compressContent = (content: string): string => {
    return content;
  };

  const updateContent = (content: string) => {
    setContent(content);

    const compressedContent = compressContent(content);

    setQueryParams(
      { data: compressedContent },
      { replace: true, preventScrollReset: true }
    );
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-start bg-stone-900">
      <div className="mx-2 my-1 flex w-full max-w-xl flex-1 flex-col px-2 pt-2">
        <a
          href={window.location.origin}
          className="cursor-pointer rounded-lg bg-stone-800 py-2 text-center font-mono text-3xl"
        >
          <span className="text-rose-500">{`< `}</span>
          <span className="font-medium text-rose-50">{`Unity`}</span>
          <span className="text-rose-500">{`.`}</span>
          <span className="font-medium text-rose-50">{`Codes`}</span>
          <span className="text-rose-500">{` />`}</span>
        </a>

        <div className="my-2 rounded-lg bg-stone-100/95 px-4 shadow-none">
          {content.length > 0 && (
            <article className="prose prose-sm prose-stone w-full rounded-lg prose-h1:mt-8 prose-a:text-blue-400 prose-img:container prose-img:rounded-lg prose-hr:border-stone-300">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="break-words"
              >
                {content}
              </ReactMarkdown>
            </article>
          )}
          <div className="my-4 flex flex-col rounded-lg border border-stone-400/40 bg-stone-200 py-2 px-3 text-sm shadow-inner">
            <div className="my-2">
              <textarea
                rows={10}
                placeholder="Markdown Content"
                className="block w-full rounded-md border-gray-300 text-xs text-stone-700 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                defaultValue={content}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  updateContent(e.target.value);
                }}
              />
            </div>
            <div className="my-1 flex items-center justify-between px-1 text-xs font-medium text-stone-600">
              <a
                className="cursor-pointer italic text-blue-400 underline"
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Markdown Guide
              </a>
              <div>
                {`${content.length} characters | ${
                  content.split(/\W+/).length - 1
                } words`}
              </div>
            </div>
            <div className="my-2 rounded-lg border border-stone-400/40 bg-stone-100/50 py-1 px-2 font-mono text-xs text-stone-700">
              <div className="my-2 text-center text-xl font-semibold text-stone-600">
                {`Data Link`}
              </div>

              <div className="my-0.5 w-full indent-2">
                {`• A data link contains the entire message`}
              </div>
              <div className="my-0.5 w-full indent-4">
                {`• "`}
                <span className=" font-semibold text-rose-500">{`${window.location.origin}`}</span>
                {`" is the host`}
              </div>
              {params["code"] && (
                <div className="my-0.5 w-full indent-4">
                  {`• "`}
                  <span className=" font-semibold text-yellow-500">{`${
                    window.location.pathname.split(":")[0]
                  }`}</span>
                  {`" is the custom unity code`}
                </div>
              )}
              <div className="my-0.5 w-full indent-4">
                {`• "`}
                <span className=" font-semibold text-blue-500">{`?data=...`}</span>
                {`" is the entire message`}
              </div>
              <div className="my-0.5 w-full indent-2">
                {"• Share it, bookmark it, "}
                <span className="font-semibold italic text-green-500 underline">
                  {"always free"}
                </span>
              </div>

              <div className="my-2 flex w-full overflow-hidden whitespace-nowrap break-keep rounded-lg border border-stone-400/40 bg-stone-200/50">
                <div className="border-r border-stone-300 p-2 font-semibold text-rose-500">
                  {window.location.origin}
                </div>
                <div className="border-r border-stone-300 p-2 font-semibold text-yellow-500">
                  {window.location.pathname}
                </div>
                <div className="py-2 pl-1 pr-2 font-semibold text-blue-500">
                  <span className="">{"?data="}</span>
                  <span className="font-extralight italic text-stone-500">
                    {window.location.search.split("?data=")[1]}
                  </span>
                </div>
              </div>

              <div className="mt-2 mb-1 flex cursor-pointer flex-col items-center text-center text-sm font-medium">
                <div
                  onClick={() => {
                    copy(window.location.href, {
                      format: "text/plain",
                      message: "",
                    });
                    toast.success(`Copied to clipboard!`, toastOptions);
                  }}
                  className="w-1/2 rounded-lg border-2 border-green-400 bg-green-50 py-1 text-green-700 shadow-md shadow-green-100 hover:border-green-300 hover:bg-green-100 hover:text-green-800 hover:shadow-green-200"
                >
                  Copy Data Link
                </div>
              </div>
            </div>

            <div className="my-1 rounded-lg border border-stone-400/40 bg-stone-100/50 py-1 px-2 font-mono text-xs text-stone-700">
              {window.location.pathname.length > 1 ? (
                <>
                  <div className="my-1 text-center text-xl font-semibold text-stone-600">
                    {`${code}`}
                  </div>

                  <div className="my-1 h-2 overflow-hidden rounded-md bg-white">
                    <div className="h-2 w-1 bg-rose-700"></div>
                  </div>

                  <div className="text-md my-1 text-center font-extralight text-stone-400">
                    {`Depleted (0%)`}
                  </div>
                </>
              ) : (
                <>
                  <div className="my-2 text-center text-xl font-semibold text-stone-600">
                    {`Custom Link`}
                  </div>

                  <div className="my-2 w-full overflow-hidden whitespace-nowrap indent-2">
                    {`• A custom link - ${window.location.host}/`}
                    <span className="text-yellow-500">
                      {code ? code : "yourcodehere"}
                    </span>
                  </div>

                  <div className="my-1">
                    <textarea
                      rows={1}
                      placeholder=""
                      className="block w-full rounded-md border-gray-300 text-xs text-stone-700 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                      defaultValue={""}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}

              <div className="my-1 mt-2 flex">
                <div className="mx-1 flex-1 rounded-lg border-2 border-stone-400 bg-stone-500/10 py-1 text-center text-sm font-medium italic text-stone-400 shadow-md shadow-stone-100">
                  {"Coming"}
                </div>
                <div className="mx-1 flex-1 rounded-lg border-2 border-stone-400 bg-stone-500/10 py-1 text-center text-sm font-medium italic text-stone-400 shadow-md shadow-stone-100">
                  {"Soon"}
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 flex justify-center text-xs font-light">{`Unity.Codes v0.0.1`}</div>
        </div>
      </div>
    </div>
  );
};
